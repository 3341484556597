import React, { useEffect, useState } from "react";
import { FiMenu, FiUsers } from "react-icons/fi";
import { IoRestaurantOutline } from "react-icons/io5";
import { MdOutlineDomainAdd, MdLogout, MdClose } from "react-icons/md";

const SideBar = ({ isHamburger = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobil, setIsMobil] = useState(false);

  useEffect(() => {
    setIsMobil(isHamburger);
  });

  if (!isOpen && !isMobil) {
    return (
      <div
        className="hidden md:flex flex-col min-w-[240px] h-screen bg-white  border-purple-400"
        style={{ background: "#111827", color: "white" }}
      >
        <div className="text-[15px]  p-5 space-y-10">
          <a href="#">
            <img
              src={"/logo.svg"}
              style={{
                height: "15px",
              }}
            />
          </a>
          <a href="/customers" className="flex items-center gap-5 ">
            Müşteriler <IoRestaurantOutline />{" "}
          </a>
          <a href="/new-customer" className="flex items-center gap-5 ">
            Restoran Ekle <MdOutlineDomainAdd />{" "}
          </a>
          <a
            href="/"
            onClick={() => localStorage.removeItem("token")}
            className="flex items-center gap-5 "
          >
            Çıkış yap <MdLogout />{" "}
          </a>
        </div>
      </div>
    );
  } else if (isMobil) {
    return !isOpen ? (
      <FiMenu
        style={{ fontSize: "30px" }}
        className=" md:hidden"
        onClick={() => setIsOpen(!isOpen)}
      />
    ) : (
      <div>
        <div className="flex flex-col absolute top-0 left-0 w-full h-screen bg-white border-2 border-purple-400">
          <MdClose
            onClick={() => setIsOpen(!isOpen)}
            className="absolute top-0 right-0"
            style={{ fontSize: "50px" }}
          />

          <div className="text-[34px] p-5 space-y-10">
            <a href="/customers" className="flex items-center gap-5 ">
              Müşteriler <IoRestaurantOutline />{" "}
            </a>
            <a href="/new-customer" className="flex items-center gap-5 ">
              Restoran Ekle <MdOutlineDomainAdd />{" "}
            </a>
            <a
              href="/"
              onClick={() => localStorage.removeItem("token")}
              className="flex items-center gap-5 "
            >
              Çıkış yap <MdLogout />{" "}
            </a>
          </div>
        </div>
      </div>
    );
  }
};

export default SideBar;
