import React from 'react'

import { useState, useEffect } from 'react';
import { redirect, useNavigate, useParams } from 'react-router-dom';
import { AddModal } from '../components/AddModal';
import { createPortal } from 'react-dom';
import SideBar from '../components/SideBar';

const MenuManagment = () => {

  let { id } = useParams()

  const [data, setData] = useState();
  const [isLoading, setLoading] = useState(false);
  const [menu, setMenu] = useState({});
  const [isAdd, setIsAdd] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [editMenu, setEditMenu] = useState({})
  const [isSettings, setIsSettings] = useState(false)
  const [settings,setSettings] = useState({}) 

  const navigate = useNavigate()
  useEffect(() => {
    const token = localStorage.getItem('token')

    if (!token) {
      navigate('/')
    } else {
      fetch(`http://207.154.205.31:3410/menu/get-menu/${id}`, {
        method: "GET",
        headers: {
          Authorization: token,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setData(data);
          setLoading(false);
          //  if (data?.status === 404) redirect("/");
        });
    }
  }, []);

  const handleMenu = (menu) => (
    setIsEdit(true)
  )

  const handleSettings = () => {

  }


  const handleChange = (e) => {
    const { name, value } = e.target
  }

  const handleSubmit = async () => {
    const token = localStorage.getItem("token")

    const res = await fetch("",
      {
        method: "POST",
        headers: {
          Authorization: token
        }
      }
    )
    const data = await res.json()

    return alert("Ekleme başarılı")
  }



  return (
    <div>
      <div className="flex relative h-screen bg-gray-50 dark:bg-gray-900">
        <SideBar isHamburger={false} />

        {!isLoading && (
          <>
            <div className="flex flex-col flex-1 w-full">
              <header className="z-10 py-4 bg-white shadow-md dark:bg-gray-800">
                <div className="container flex items-center justify-between h-full px-6 mx-auto text-purple-600 dark:text-purple-300">
                  {/* Mobile hamburger */}
                  <div className='w-[200px] md:w-[800px]'> {id} adlı restoranı görüntülemeketesiniz. </div>

                        <SideBar isHamburger={true} />

                        <img
                          className="hidden md:block object-cover w-10 h-10 rounded-full"
                          src={data?.avatar}
                          alt=""
                          aria-hidden="true"
                        />
                      </div>
              </header>
              <main className="h-full overflow-y-auto">
                <div className="container px-6 mx-auto grid">
                  <h2 className="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
                    Menü Yönetimi
                  </h2>
                  {/* CTA */}

                  {/* Cards */}
                  <div className="grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-4">
                    {/* Card */}
                    <div className="flex items-center p-4 bg-white rounded-lg shadow-xs dark:bg-gray-800">
                      <div className="p-3 mr-4 text-orange-500 bg-orange-100 rounded-full dark:text-orange-100 dark:bg-orange-500">
                        <svg
                          className="w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
                        </svg>
                      </div>
                      <div>
                        <p className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">
                          Toplam menü
                        </p>
                        <p className="text-lg font-semibold text-gray-700 dark:text-gray-200">
                          {data?.menu?.length || 0}
                        </p>
                      </div>
                    </div>
                    {/* Card */}
                    <div className="flex items-center p-4 bg-white rounded-lg shadow-xs dark:bg-gray-800">
                      <div className="p-3 mr-4 text-green-500 bg-green-100 rounded-full dark:text-green-100 dark:bg-green-500">
                        <svg
                          className="w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fillRule="evenodd"
                            d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                      <div>
                        <p className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">
                          Hesap durumu
                        </p>
                        <p className="text-lg font-semibold text-gray-700 dark:text-gray-200">
                          Aktif
                        </p>
                      </div>
                    </div>
                    {/* Card */}
                    <div className="flex items-center p-4 bg-white rounded-lg shadow-xs dark:bg-gray-800">
                      <div className="p-3 mr-4 text-blue-500 bg-blue-100 rounded-full dark:text-blue-100 dark:bg-blue-500">
                        <svg
                          className="w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
                        </svg>
                      </div>
                      <div>
                        <p className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">
                          Sunucu durumu
                        </p>
                        <p className="text-lg font-semibold text-gray-700 dark:text-gray-200">
                          Stabil
                        </p>
                      </div>
                    </div>
                    {/* Card */}
                    <div className="flex items-center p-4 bg-white rounded-lg shadow-xs dark:bg-gray-800">
                      <div className="p-3 mr-4 text-teal-500 bg-teal-100 rounded-full dark:text-teal-100 dark:bg-teal-500">
                        <svg
                          className="w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fillRule="evenodd"
                            d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                      <div>
                        <p className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">
                          QR KOD
                        </p>
                        <p className="text-lg font-semibold text-gray-700 dark:text-gray-200">
                          Oluşturulmuş
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* New Table */}


                  <div className="flex  justify-between">
                    <button
                      onClick={() => setIsSettings(true)}
                      className='flex justify-center items-center w-[180px] h-[50px] rounded-[8px] bg-black/70 text-white '
                    >
                      Restoran özellikleri
                      <svg
                        className="w-5 h-5 ml-2 text-white dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          width="2"
                          d="m10.8 17.8-6.4 2.1 2.1-6.4m4.3 4.3L19 9a3 3 0 0 0-4-4l-8.4 8.6m4.3 4.3-4.3-4.3m2.1 2.1L15 9.1m-2.1-2 4.2 4.2"
                        />
                      </svg>
                    </button>
                    <AddModal open={isSettings} onClose={() => setIsSettings(false)}>
                      <div className='flex flex-col p-5w-full space-y-[20px]'>
                        <span className='text-black/90 text-[36px]'>Restoran Özellikleri</span>
                        <label className='text-[20px]'>Restaurant adı</label>
                        <input type="text" name='title' placeholder='restoran ismi'  onChange={handleChange} className='border-2  border-gray-500 focus:outline-green-600  rounded-[4px] w-[300px] p-2 h-[40px]' />

                        <label className='text-[20px]'>Restoran fotoğrafı</label>
                        <input type="text" name='avatar' placeholder='www.' onChange={handleChange} className='border-2 border-gray-500 rounded-[4px] w-[300px] p-2 h-[40px] focus:outline-green-600 ' />


                        <label className='text-[20px]'>Restoran arka plan fotoğrafı</label>
                        <input type="text" name='banner' placeholder='www.' onChange={handleChange} className='border-2  border-gray-500 rounded-[4px] w-[300px] h-[40px] p-2 focus:outline-green-600' />

                        <button className='bg-green-200 w-full border-4 rounded-[8px] border-green-400 text-[24px] font-bold h-[50px] sm:w-[300px]'>Kaydet</button>
                      </div>
                    </AddModal>
                    <button
                      type="button"
                      onClick={() => setIsAdd(true)}
                      className="flex justify-center items-center bg-green-400 w-[100px] h-[50px] rounded-[8px] "
                    >
                      <svg
                        className="w-5 h-5 mr-[10px] text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 12h14m-7 7V5"
                        />
                      </svg>
                      Yemek
                    </button>
                  </div>

                  <AddModal open={isAdd} onClose={() => setIsAdd(false)} >
                    <div className='flex flex-col '>
                      <span className='text-[34px] text-blue-400 mb-[20px]'>Menüye ürün ekle</span>

                      <label className=''>Ürün Adı</label>
                      <input type='text' placeholder='ürün adı' onChange={handleChange} name='title' className='w-[300px] p-[5px] mt-[5px] mb-[20px] h-[40px] border-2  outline-none' />

                      <label className=''>Ürün Açıklaması</label>
                      <input type='text' placeholder='ürün açıklaması' onChange={handleChange} name='description' className='w-[300px] p-[5px] mt-[5px] mb-[20px] h-[40px] border-2  outline-none' />


                      <label className=''>Kategori</label>
                      <input type='text' placeholder='ürün kategorisi' onChange={handleChange} name='category' className='w-[300px] p-[5px] mt-[5px] mb-[20px] h-[40px] border-2  outline-none' />


                      <label className=''>Fiyat</label>
                      <input type='text' placeholder='ürün fiyatı' onChange={handleChange} name='category' className='w-[300px] p-[5px] mt-[5px] mb-[20px] h-[40px] border-2  outline-none' />

                      <button onClick={handleSubmit} className='w-full h-[58px] text-[24px] rounded-[10px] bg-green-400 focus:border-green-200 border-4  border-gray-100 '>Ürün ekle</button>
                    </div>

                  </AddModal>

                  <div className="w-full overflow-hidden rounded-lg shadow-xs">
                    <div className="w-full overflow-x-auto">
                      <table className="w-full whitespace-no-wrap">
                        <thead>
                          <tr className="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
                            <th className="px-4 py-3">Yemek</th>
                            <th className="px-4 py-3">Fiyat</th>
                            <th className="px-4 py-3">Kategori</th>
                            <th className="px-4 py-3">Düzenle</th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
                          {data?.menu?.map((item, index) => (
                            <tr
                              key={index}
                              className="text-gray-700 dark:text-gray-400"
                            >
                              <td className="px-4 py-3">
                                <div className="flex items-center text-sm">
                                  {/* Avatar with inset shadow */}
                                  <div className="relative hidden w-8 h-8 mr-3 rounded-full md:block">
                                    <img
                                      className="object-cover w-full h-full rounded-full"
                                      src="https://placehold.co/100x100"
                                      alt="Food Image"
                                      loading="lazy"
                                    />
                                    <div
                                      className="absolute inset-0 rounded-full shadow-inner"
                                      aria-hidden="true"
                                    />
                                  </div>
                                  <div>
                                    <p className="font-semibold">{item?.title}</p>
                                    <p className="text-xs text-gray-600 dark:text-gray-400">
                                      {item?.description}
                                    </p>
                                  </div>
                                </div>
                              </td>
                              <td className="px-4 py-3 text-sm">{item?.price} ₺</td>
                              <td className="px-4 py-3 text-xs">
                                <span className="px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-full dark:bg-green-700 dark:text-green-100">
                                  {item?.category}
                                </span>
                              </td>
                              <td className="px-4 py-3 text-sm flex items-center align-center">
                                <button onClick={() => setIsEdit(true)}>
                                  <svg
                                    className="w-6 h-6 text-gray-800 dark:text-white"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      d="m10.8 17.8-6.4 2.1 2.1-6.4m4.3 4.3L19 9a3 3 0 0 0-4-4l-8.4 8.6m4.3 4.3-4.3-4.3m2.1 2.1L15 9.1m-2.1-2 4.2 4.2"
                                    />
                                  </svg>
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <AddModal open={isEdit} onClose={() => setIsEdit(false)} >
                      <div className='flex flex-col'>
                        <span className='text-[34px] text-blue-400 mb-[20px]'>Ürünü düzenle</span>

                        <label className=''>Ürün Adı</label>
                        <input type='text' value={editMenu?.title} placeholder='ürün adı' onChange={handleChange} name='title' className='w-[300px] p-[5px] mt-[5px] mb-[20px] h-[40px] border-2  outline-none' />

                        <label className=''>Ürün Açıklaması</label>
                        <input type='text' value={editMenu?.description} placeholder='ürün açıklaması' onChange={handleChange} name='description' className='w-[300px] p-[5px] mt-[5px] mb-[20px] h-[40px] border-2  outline-none' />


                        <label className=''>Kategori</label>
                        <input type='text' value={editMenu?.category} placeholder='ürün kategorisi' onChange={handleChange} name='category' className='w-[300px] p-[5px] mt-[5px] mb-[20px] h-[40px] border-2  outline-none' />


                        <label className=''>Fiyat</label>
                        <input type='text' value={editMenu?.price} placeholder='ürün fiyatı' onChange={handleChange} name='category' className='w-[300px] p-[5px] mt-[5px] mb-[20px] h-[40px] border-2  outline-none' />

                        <button onClick={handleSubmit} className='w-full h-[58px] text-[24px] rounded-[10px] bg-green-400 focus:border-green-200 border-4  border-gray-100 '>Ürünü düzenle</button>
                      </div>

                    </AddModal>
                    <div className="grid px-4 py-3 text-xs font-semibold tracking-wide text-gray-500 uppercase border-t dark:border-gray-700 bg-gray-50 sm:grid-cols-9 dark:text-gray-400 dark:bg-gray-800">
                      <span className="flex items-center col-span-3">
                        Tüm menüler listelendi
                      </span>
                      <span className="col-span-2" />
                    </div>
                  </div>
                  {/* Charts */}
                  <h2 className="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200"></h2>
                </div>
              </main>
            </div>
          </>

        )}

      </div>



    </div>
  )
}

export default MenuManagment