import React, { useState } from "react";
import SideBar from "../components/SideBar";

const AddRestaurant = () => {
  const [form, setForm] = useState();

  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = () => {};

  return (
    <div
      className="flex     h-screen   "
      style={{ backgroundColor: "#384154" }}
    >
      <SideBar />

      <div className="flex flex-col w-full">
        <header
          className="z-10 py-4 bg-white  w-full shadow-md dark:bg-gray-800"
          style={{ backgroundColor: "#111827" }}
        >
          <div className=" flex items-center justify-between  px-6 mx-auto text-purple-600 dark:text-purple-300">
            <span className="text-white text-[36px]">Restoran Ekle</span>

            <SideBar isHamburger={true} />
          </div>
        </header>
        <div className="flex flex-col px-5 space-y-[20px] mt-10">
          <label className="text-[20px]">Restaurant adı</label>
          <input
            type="text"
            name="title"
            placeholder="restoran ismi"
            onChange={handleChange}
            className="border-2  border-gray-500 focus:outline-green-600  rounded-[4px] w-[300px] p-2 h-[40px]"
          />

          <label className="text-[20px]">Restorant fotoğrafı</label>
          <input
            type="text"
            name="title"
            placeholder="restoran fotoğrafı"
            onChange={handleChange}
            className="border-2  border-gray-500 focus:outline-green-600  rounded-[4px] w-[300px] p-2 h-[40px]"
          />

          <label className="text-[20px]">Restoran arka plan fotoğrafı</label>
          <input
            type="text"
            name="title"
            placeholder="restoran ismi"
            onChange={handleChange}
            className="border-2  border-gray-500 focus:outline-green-600  rounded-[4px] w-[300px] p-2 h-[40px]"
          />
          <button className="bg-green-200 w-[300px] border-4 rounded-[8px] border-green-400 text-[24px] font-bold h-[50px] sm:w-[300px]">
            Kaydet
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddRestaurant;
