import React, { useEffect, useState } from "react";
import SideBar from "../components/SideBar";
import { useNavigate } from "react-router-dom";
const Customers = () => {
  const [customers, setCustomer] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (!token) {
      navigate("/");
    }
    fetch("http://207.154.205.31:3410/admin/list-customers", {
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) navigate("/");
        setCustomer(data.restaurants);
        setIsLoading(false);
      });
  }, []);

  return (
    <div className="flex ">
      <SideBar />

      <div
        className="flex flex-col  w-full"
        style={{ backgroundColor: "#384154" }}
      >
        <header
          className="z-10 py-4 bg-white  w-full shadow-md  "
          style={{ backgroundColor: "#111827" }}
        >
          <div className=" flex items-center justify-between  px-6 mx-auto text-white ">
            <span className=" text-[36px]">Restorantlar</span>

            <SideBar isHamburger={true} />
          </div>
        </header>
        {isLoading ? (
          <div className="flex px-4 flex-wrap gap-[40px] mt-10">
            <div className="animate-pulse ease-in  bg-gradient-to-r to-blue-300 from-purple-200 border-2 border-gray-300 rounded-[8px] w-full max-w-[300px] h-[200px]"></div>
            <div className="animate-pulse ease-in  bg-gradient-to-r to-blue-300 from-purple-200 border-2 border-white-300 rounded-[8px] w-full max-w-[300px] h-[200px]"></div>
            <div className="animate-pulse ease-in  bg-gradient-to-r to-blue-300 from-purple-200 border-2 border-gray-300 rounded-[8px] w-full max-w-[300px] h-[200px]"></div>
          </div>
        ) : (
          <div className="flex gap-[40px] px-4 flex-wrap mt-10 ">
            {customers.map((i) => (
              <div
                className="flex flex-col justify-between w-[300px] h-[200px]   rounded-[20px] p-5"
                style={{ background: "#0a0909" }}
              >
                <span className="text-[18px] text-white">
                  {" "}
                  {i.restaurant_name}{" "}
                </span>
                <a
                  href={`dashboard/${i.restaurant_id}`}
                  className=" text-[20px] bg-white px-10 py-2 rounded-[8px] text-black"
                >
                  İncele
                </a>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Customers;
