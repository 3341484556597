import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom';



export const AddModal = ({ children,open,onClose}) => {

    const token = localStorage.getItem("token")


    const handleSubmit = async () => {

      const res = await  fetch('', {
            method: "PUT",
            headers: {
                Authorization: token,
            }
        })
      const data = await res.json()
    }

    if(!open) return null

    const modalEl = (
        
        <div onClick={onClose} className='w-full top-0 fixed flex flex-col justify-center items-center bg-gray-500  bg-opacity-70     z-30 h-screen '>
            <div onClick={(e) => e.stopPropagation()} className='sm:w-[40%] w-[90%] h-max p-5  bg-gradient-to-r  from-gray-100 to-slate-100 rounded-[8px] flex justify-center items-center '>
                {children}
            </div>
        </div>   


    )

   return  createPortal(modalEl,document.getElementById('modal')) 
}

