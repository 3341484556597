import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Routes, Route} from "react-router-dom"
import MenuManagment from './Pages/MenuManagment';
import Auth from './Pages/Auth';
import Customers from './Pages/Customers';
import  AddRestaurant  from './Pages/AddRestaurant';

function App() {
  return (
    <BrowserRouter >
      <Routes>
        <Route path='' index element={<Auth />}  />
        <Route path='/customers' element={<Customers />}  />
        <Route path='/dashboard/:id' element={<MenuManagment />}  />
        <Route path='/new-customer' element={<AddRestaurant />}  />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
